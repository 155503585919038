<template>
    <div class="start-box">
        <div class="start-center">
            <div class="select-box" v-show="status != 3 && status != 4">
                <select class="select_list" @change="handleCountryChange" v-model="curCountryName">
                    <option v-for="(item, index) in countryList" :key="'country' + index" :value="item">
                        {{ item }}
                    </option>
                </select>
                <div class="down_arrow"></div>
            </div>
            <div class="select-box" v-show="status != 3 && status != 4">
                <select class="select_list" v-model="curDocumentInfo" @change="handleDocumentChange">
                    <option v-for="(item, index) in allSpecs[curCountryName]" :key="'document' + index" :value="item">
                        {{ item.document_type }}
                    </option>
                </select>
                <div class="down_arrow"></div>
            </div>
            <!-- upload -->
            <div class="upload-box" v-show="status == 1 || status == 2">
                <!-- 上传页 -->
                <div class="first-part" v-show="status == 1">
                    <div class="upload-total">
                        <img src="../../../assets/images/add-icon.png" class="add-icon" alt="" />
                        <div class="add-text">{{ $t("message.start.uploadText") }}</div>
                    </div>
                    <input type="file" accept="image/*" class="input-file" @click="handleFileClick" @change="handleFileChange" />
                </div>
                <!-- 上传中 -->
                <div class="second-part" v-show="status == 2">
                    <div class="process">
                        <div class="process-bar" :style="{ width: processBar + '%' }"></div>
                    </div>
                    <div>{{ processBar }}%</div>
                </div>
            </div>
            <!-- 上传完成 -->
            <div v-show="status == 3 || status == 4">
                <div class="three-part">
                    <div class="three-part-box">
                        <img :src="uploadUrl" class="upload-img" alt="" />
                    </div>
                </div>
                <div class="three-part-document">
                    <div>
                        {{ curDocumentInfo.document_type }}
                    </div>
                    <div class="again-btn" @click="handleAgainClick">{{ $t("message.start.againText") }}</div>
                </div>
                <div v-show="status == 3">
                    <div class="download-text">{{ $t("message.index.downloadText") }} <span>$3.99</span></div>
                    <div class="real-price">$1.99</div>
                    <div class="price-off">({{ $t("message.index.priceOff") }})</div>
                </div>
                <div class="download-load" v-show="status == 4">
                    <div class="load-text">{{ $t("message.start.loadText") }}</div>
                </div>
            </div>

            <!-- tips -->
            <div class="upload-tips-box" v-show="status != 3 && status != 4">
                <div>{{ $t("message.start.uploadTips1") }}</div>
                <div>{{ $t("message.start.uploadTips2") }}</div>
            </div>
            <div class="paypal-box" v-show="status == 3">
                <div id="paypal-button-container"></div>
            </div>
            <div class="download-photo" v-show="status == 4">
                <div class="download-photo-box" @click="handleDownloadClick(true)">
                    <van-loading size="24" v-if="isFinshPhoto" class="load-icon" color="#2a2a2a" />
                    {{ $t("message.start.download") }}
                </div>
            </div>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import { getCountries } from "@/api/common.js";
import { uploadPhoto, parsePhoto, getFinalPhoto } from "@/api/start.js";
import { reportWebVitals } from "@/utils/ga.js";
import Notify from "vant/lib/notify";
import "vant/lib/notify/style";
import "vant/lib/loading/style";
import { mapState } from "vuex";
// import Footer from "@/components/Footer.vue";
export default {
    name: "Start",
    data() {
        return {
            countryList: [],
            curCountryName: "",
            documentList: {},
            curDocumentInfo: {},
            allSpecs: {},

            id: null,
            locale: "",
            messages: null,
            photoKey: "",
            uploadUrl: "",
            status: 1, //1 上传页面 2上传中 3 上传完成 4 支付成功
            processBar: 0,
            timer: null,
            orderID: "",
            isFinshPhoto: false,
            imageDataURL: ""
        };
    },
    components: {
        // Footer,
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "documentInfo"
    ]),
    methods: {
        handleCountryChange() {
            if (!this.allSpecs[this.curCountryName]) {
                return;
            }
            this.curDocumentInfo = this.allSpecs[this.curCountryName][0];
            // 切换国家打点
            reportWebVitals("country_select_start", 1, "spec", "", {
                countryName: this.curCountryName
            });
        },
        handleDocumentChange() {
            // 切换类型打点
            reportWebVitals("photo_type_select_start", 1, "spec", "", {
                photoId: this.curDocumentInfo.id
            });
        },
        // 重新上传
        handleAgainClick() {
            this.resetUpload();
            this.uploadUrl = "";
            this.orderID = "";
            reportWebVitals("reUpload_success_start", 1, "parse", "");
        },
        // 点击上传
        handleFileClick() {
            reportWebVitals("upload_btn_click_start", 1, "parse", "core");
        },
        // 选择文件
        async handleFileChange(e) {
            var file = e.target?.files[0];
            if (!file) {
                const photoInvalidMsg =
                    this.messages[this.locale].message.start.photoInvalidMsg;
                Notify({
                    type: "danger",
                    message: photoInvalidMsg
                });
                return;
            }

            let info = null;
            try {
                info = await this.getFilePhotoInfo(file);
            } catch (error) {
                console.error(error);
            }

            if (!info) {
                reportWebVitals("get_filephoto_info_error");
                const photoInvalidMsg =
                    this.messages[this.locale].message.start.photoInvalidMsg;
                Notify({
                    type: "danger",
                    message: photoInvalidMsg
                });
                return;
            }
            if (info.width > 3000 || info.height > 3000) {
                const photoInvalidMsg =
                    this.messages[this.locale].message.start.photoInvalidMsg2;
                Notify({
                    type: "danger",
                    message: photoInvalidMsg
                });
                reportWebVitals("file_photo_size_error", "", "parse","", {
                    width: info.width,
                    height: info.height
                });
                return;
            }

            reportWebVitals("upload_loading_start", "", "parse");
            this.processBar = 0;
            this.status = 2;
            this.setProcessAni();
            this.uploadPhoto(file);

            e.target.value = "";
        },
        // 获取上传文件信息
        getFilePhotoInfo(file) {
            return new Promise((reslove, reject) => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    //加载图片获取图片真实宽度和高度
                    var image = new Image();
                    image.onload = function () {
                        var width = image.width;
                        var height = image.height;
                        reslove({ width, height });
                    };
                    image.src = data;
                };
                reader.onerror = function (e) {
                    reject(e);
                };
                reader.readAsDataURL(file);
            });
        },
        // 上传进度
        setProcessAni() {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                if (this.processBar === 94) {
                    clearInterval(this.timer);
                    return;
                }
                this.processBar = this.processBar + 2;
            }, 100);
        },
        resetUpload() {
            this.status = 1;
            this.processBar = 0;
            clearInterval(this.timer);
        },
        // 初始化支付按钮
        initPayPalButton() {
            var that = this;
            window.paypal
                .Buttons({
                    style: {
                        shape: "pill",
                        color: "gold",
                        layout: "vertical",
                        label: "paypal"
                    },

                    createOrder: function (data, actions) {
                        reportWebVitals(
                            "pay_btn_click_start",
                            "",
                            "pay",
                            "core",
                            {
                                countryName: that.curCountryName,
                                currency: "USD",
                                price: "1.99"
                            }
                        );
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "USD",
                                        value: "1.99"
                                    }
                                }
                            ]
                        });
                    },

                    onApprove: function (data, actions) {
                        return actions.order
                            .capture()
                            .then(function (orderData) {
                                if (orderData.id == null) {
                                    console.warn("Capture error");
                                    return;
                                }
                                that.orderID = orderData.id;
                                that.onPaymentSuccess();
                            });
                    },
                    onCancel: function () {
                        /*
                         * Buyer cancelled the payment
                         */
                        reportWebVitals("pay_cancel_start", "", "pay", "");
                    },

                    onError: function (err) {
                        console.log(err);
                        reportWebVitals("pay_fail_start", "", "pay", "", {
                            error: JSON.stringify(err)
                        });
                    }
                })
                .render("#paypal-button-container");
        },
        // 支付成功
        onPaymentSuccess() {
            reportWebVitals("pay_success_start", "", "pay", "core");
            this.status = 4;
            this.getFinalPhoto();
        },
        // 下载图片
        handleDownloadClick(flag) {
            this.isFinshPhoto = true;

            this.downLoad("photo", this.uploadUrl, flag);
            if (flag) {
                // 点击下载按钮
                reportWebVitals("download_btn_click", "", "download", "");
            }
        },
        downLoad(downloadName, url, status) {
            reportWebVitals("download_start", 1, "download", "", {
                download_type: status ? "click" : "auto"
            });

            const tag = document.createElement("a");
            // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
            tag.setAttribute("download", downloadName.replace(/\./g, "。"));
            const image = new Image();
            // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
            image.src = url + "?time=" + new Date().getTime();

            //重要，设置 crossOrigin 属性，否则图片跨域会报错
            image.setAttribute("crossOrigin", "Anonymous");
            // 图片未加载完成时操作会报错
            image.onload = () => {
                reportWebVitals("download_onload", 1, "download", "", {
                    download_type: status ? "click" : "auto"
                });

                let url = "";
                if (this.imageDataURL) {
                    url = this.imageDataURL;
                } else {
                    url = this.getImageDataURL(image);
                }
                tag.href = url;
                tag.click();
                this.isFinshPhoto = false;
                this.imageDataURL = url;
                reportWebVitals("download_success_start", 1, "download", "", {
                    download_type: status ? "click" : "auto"
                });
            };
        },

        getImageDataURL(image) {
            // 创建画布
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            // 以图片为背景剪裁画布
            ctx.drawImage(image, 0, 0, image.width, image.height);
            // 获取图片后缀名
            const extension = image.src
                .substring(image.src.lastIndexOf(".") + 1)
                .toLowerCase();
            // 某些图片 url 可能没有后缀名，默认是 png
            return canvas.toDataURL("image/" + extension, 1);
        },
        getCountries() {
            getCountries()
                .then((res) => {
                    if (res?.body?.code == 0) {
                        const data = res.body.data;
                        this.allSpecs = data?.specs?.specs || {};
                        this.countryList = data?.specs?.countries || [];

                        // url 中自带国家id
                        if (this.id) {
                            // 根据id 获取国家和类型信息
                            for (let k in this.allSpecs) {
                                const item = this.allSpecs[k];
                                for (let i = 0; i < item.length; i++) {
                                    if (item[i].id == this.id) {
                                        this.curCountryName = k;
                                        this.curDocumentInfo =
                                            this.allSpecs[this.curCountryName][
                                                i
                                            ];
                                        break;
                                    }
                                }
                            }
                        } else if (this.documentInfo) {
                            this.curCountryName = this.documentInfo.country;
                            this.curDocumentInfo = this.documentInfo;
                        } else {
                            this.curCountryName =
                                (this.countryList && this.countryList[0]) || "";
                            this.documentList =
                                this.allSpecs[this.curCountryName];
                            this.curDocumentInfo =
                                (this.documentList && this.documentList[0]) ||
                                "";
                        }
                        reportWebVitals("spec_success", 1, "spec", "", {
                            code: res.body.code
                        });
                    } else {
                        reportWebVitals("spec_fail", 1, "spec", "", {
                            code: res.body.code
                        });
                    }
                })
                .catch((err) => {
                    reportWebVitals("spec_fail", 1, "spec", "", { code: "-1" });
                    console.error(err);
                });
        },
        // 获取 photokey
        uploadPhoto(file) {
            uploadPhoto(file)
                .then((res) => {
                    this.photoKey = res.photoKey;
                    // 上传图片成功打点 获取到photoKey
                    reportWebVitals(
                        "upload_success_start",
                        "",
                        "parse",
                        "core"
                    );

                    this.parsePhoto(res.signedUrl, res.photoKey);
                })
                .catch((err) => {
                    this.resetUpload();
                    const uploadErr =
                        this.messages[this.locale].message.start.uploadErr;
                    Notify({
                        type: "danger",
                        message: uploadErr
                    });

                    // 上传图片失败打点
                    reportWebVitals("upload_fail_start", "", "parse", "", {
                        error: JSON.stringify(err)
                    });

                    console.error(err);
                });
        },
        // 水印图
        parsePhoto(signedUrl, photoKey) {
            const startTime = new Date().getTime();
            parsePhoto(signedUrl, photoKey, this.curDocumentInfo)
                .then((res) => {
                    if (res?.body?.code == 0) {
                        this.uploadUrl = res.body.data.url;
                        this.processBar = 100;
                        // 上传完成
                        this.status = 3;

                        // 带水印图片成功打点
                        reportWebVitals("parse_photo_success", "", "parse", "");
                    } else {
                        const photoInvalidMsg1 =
                            this.messages[this.locale].message.start
                                .photoInvalidMsg1;
                        Notify({
                            type: "danger",
                            message: photoInvalidMsg1
                        });
                        this.resetUpload();
                        // 带水印图片失败打点
                        reportWebVitals("parse_photo_fail", "", "parse", "", {
                            code: res?.body?.code || "-1"
                        });
                    }
                    // 生成带水印图片时间打点
                    const endTime = new Date().getTime() - startTime;
                    reportWebVitals(
                        "handle_watermark_photo_time",
                        endTime,
                        "",
                        "time"
                    );
                })
                .catch((err) => {
                    this.resetUpload();
                    // 带水印图片失败打点
                    reportWebVitals("parse_photo_fail", "", "parse", "", {
                        error: JSON.stringify(err)
                    });
                    const uploadErr =
                        this.messages[this.locale].message.start.uploadErr;
                    Notify({
                        type: "danger",
                        message: uploadErr
                    });
                    console.error(err);
                });
        },
        // 无水印图
        getFinalPhoto() {
            if (!this.orderID) {
                reportWebVitals("no_order_id");
                return;
            }
            const startTime = new Date().getTime();
            getFinalPhoto(this.orderID, this.photoKey)
                .then((res) => {
                    if (res?.body?.code == 0) {
                        this.uploadUrl = res.body.data.url;
                        const successMsg =
                            this.messages[this.locale].message.start.successMsg;
                        Notify({
                            type: "success",
                            message: successMsg
                        });
                        // 无水印成功打点
                        reportWebVitals(
                            "get_photo_success_start",
                            "",
                            "download",
                            ""
                        );
                        this.handleDownloadClick();
                    } else {
                        const errorMsg =
                            this.messages[this.locale].message.start.errorMsg;
                        Notify({
                            type: "danger",
                            message: errorMsg
                        });
                        this.status = 3;
                        // 无水印失败打点
                        reportWebVitals(
                            "get_photo_error_start",
                            "",
                            "download",
                            "",
                            {
                                code: res?.body?.code || "-1"
                            }
                        );
                    }
                    // 转成无水印时间打点
                    const endTime = new Date().getTime() - startTime;
                    reportWebVitals(
                        "handle_no_watermark_photo_time",
                        endTime,
                        "",
                        "time",
                        {
                            code: res?.body?.code || "-1"
                        }
                    );
                })
                .catch((err) => {
                    this.status = 3;
                    // 无水印失败打点
                    reportWebVitals(
                        "get_photo_error_start",
                        "",
                        "download",
                        "",
                        {
                            code: "-1",
                            error: JSON.stringify(err)
                        }
                    );
                    const uploadErr =
                        this.messages[this.locale].message.start.uploadErr;
                    Notify({
                        type: "danger",
                        message: uploadErr
                    });
                    console.error(err);
                });
        }
    },
    mounted() {
        this.locale = this.$i18n.locale;
        this.messages = this.$i18n.messages;
        this.id = this.$route.query.id;
        this.getCountries();
        this.$nextTick(() => {
            this.initPayPalButton();
        });
    }
};
</script>

<style scoped lang="scss">
.start-box {
    padding-top: 50px;
    padding-bottom: 220px;
    .start-center {
        min-height: calc(100vh - 240px);
    }
    .select-box {
        .select_list {
            width: 700px;
            height: 80px;
            margin: 0 auto 30px;
        }
    }
    .upload-box {
        width: 700px;
        height: 710px;
        margin: 30px auto 0;
        background: rgba(255, 214, 0, 0.06);
        border: 0.5px solid rgba(255, 210, 0, 0.3);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .first-part {
            .upload-total {
                .add-icon {
                    width: 120px;
                    height: 120px;
                    margin: 0 auto 50px;
                }
                .add-text {
                    font-size: 30px;
                    color: #242a33;
                    font-weight: 500;
                    text-align: center;
                }
            }
            .input-file {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                opacity: 0;
            }
        }
        .second-part {
            display: flex;
            align-items: center;
            color: #242a33;
            font-size: 26px;
            font-weight: 400;
            .process {
                width: 400px;
                height: 16px;
                background: rgba(255, 214, 0, 0.3);
                position: relative;
                margin-right: 10px;
                .process-bar {
                    position: absolute;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-image: linear-gradient(
                        270deg,
                        #ffbf00 0%,
                        #ffe100 100%
                    );
                }
            }
        }
    }
    .three-part {
        width: 700px;
        height: 710px;
        margin: 30px auto 0;
        background: #f5f5f5;
        border-radius: 12px;
        padding: 20px;

        .three-part-box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            .upload-img {
                height: 500px;
                width: auto;
            }
        }
    }
    .three-part-document {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        font-size: 30px;
        color: #242a33;
        font-weight: 400;
        margin-top: 30px;
        .again-btn {
            width: 250px;
            height: 66px;
            line-height: 62px;
            text-align: center;
            background: #f5f5f5;
            border: 1px solid #ededed;
            border-radius: 6px;
        }
    }
    .download-text {
        font-size: 32px;
        color: #242a33;
        text-align: center;
        font-weight: 400;
        margin-top: 50px;
        span {
            color: #cc210f;
            text-decoration: line-through;
        }
    }
    .download-load {
        padding-left: 10px;
        margin-top: 30px;
        width: 100%;

        .load-text {
            // width: 90%;
            margin-left: 20px;
            margin-right: 12px;
            font-size: 30px;
            opacity: 0.8;
        }
    }
    .real-price {
        font-size: 50px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin-top: 22px;
    }
    .price-off {
        font-size: 26px;
        color: #15993d;
        text-align: center;
        font-weight: 400;
        margin-top: 10px;
    }
    .upload-tips-box {
        margin: 30px auto 0;
        div {
            font-size: 30px;
            color: #242a33;
            font-weight: 400;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
.paypal-box {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 220px;
    padding: 36px 60px 0;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9;
    background: #fff;
}
.download-photo {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 130px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    .download-photo-box {
        width: 700px;
        height: 90px;
        margin: 0 auto;
        background: #ffbf00;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .load-icon {
            margin-right: 10px;
        }
    }
}
@media only screen and (device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3),
    /* iphone xr 2436x1125px at 375ppi */ screen and (device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 2),
    /* iphone xr 2436x1125px at 458ppi & ip11*/ screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    /* iphone xs 2436x1125px at 458ppi & ipx & ip11Pro */ screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
    /* iphone xs max 2688x1242px at 458ppi & ip11 pro max*/ screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
    /* ip12 pro max*/ screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
    /* ip12 */ screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .paypal-box {
        padding-bottom: 68px;
        height: 288px;
    }
    .download-photo {
        height: 198px;
    }
}
</style>
